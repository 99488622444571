import { favoritesStoresByCategoryList } from '../api/index';
import { getIconMaker } from '../util/anyFunctions';

const useFavoritesStore = async(type) =>{
    const response = await favoritesStoresByCategoryList({type});
    let stores = []
    let categories = [{value:'available', label: "Todos los servicios"}]
    if(response.status !== "fail"){
        const storesByCategories = response?.data?.categories?.map(category => {
            categories.push({value : category.value, label: category.name})
            return {
                stores: category.stores.map(store =>{
                    return {
                        ...store,
                        categoryValue: category.value,
                        categoryName: category.name,
                        iconMarker: getIconMaker(category.value),
                    }
                })
            }
        });
        stores = storesByCategories.flatMap(obj => obj.stores);
    }



    return {stores, categories}
}

export default useFavoritesStore;